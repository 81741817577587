import 'reflect-metadata';
import {SessionStub} from '@codesphere/auth-service-common/lib/session/api';
import {HttpEndpoint} from '@codesphere/rpc-common/lib/stub/Endpoint';
import {deleteCookie} from '@codesphere/ui-browser/lib/Cookie';
import {safeLocalStorage} from '@codesphere/utils-browser/lib/SafeLocalStorage';
import {
    Router,
    SerializableLocation,
    path,
} from '@codesphere/ui-browser/lib/Router';
import {
    UserActivityBrowserStub,
} from '@codesphere/user-activity-browser/lib/UserActivityBrowserStub';
import {
    dateSelected,
    eventScheduled,
} from '@codesphere/user-activity-common/lib/events/Calendly';
import {
    completeRegistration,
} from '@codesphere/user-activity-common/lib/events/CompleteRegistration';
import {
    newsletter,
} from '@codesphere/user-activity-common/lib/events/Newsletter';
import {
    pageSession,
} from '@codesphere/user-activity-common/lib/events/PageSession';
import {pageView} from '@codesphere/user-activity-common/lib/events/PageView';
import {
    ignoreErrorAsync, logErrorAsync,
} from '@codesphere/utils-common/lib/errors';
import {has} from '@codesphere/utils-common/lib/has';
import {isEmpty} from '@codesphere/utils-common/lib/isEmpty';
import {allLogRejected} from '@codesphere/utils-common/lib/promise';
import {config} from './config';
import {enableGoogleAnalytics} from './enableGoogleAnalytics';
import {enableUxTracking} from './enableUxTracking';
import './main';
import {
    Cookie,
    hasMarketingCookie,
    hasNecessaryCookie,
    hasPerformanceCookie,
    setGdprConsentCookie,
} from './gdprConsentCookie';

declare const GA_ID: string;
declare const gtag: any;
// eslint-disable-next-line @typescript-eslint/naming-convention
declare const gtag_report_conversion: (url: string) => boolean;
// eslint-disable-next-line @typescript-eslint/naming-convention
declare const Calendly: any;
declare const window: any;
// Must be ran before any other logic.
const sessionStart = Date.now();

const router = Router.create();

// eslint-disable-next-line @typescript-eslint/no-namespace
namespace CookieConsent {
    const cookieBoxElement = document.querySelector('.cookiebox');
    const cookiePrefElement = document.querySelector('.cookiepref');
    const performanceCookie
        = cookiePrefElement.querySelector(
            '.--checkbox-performance'
    ) as HTMLInputElement;
    const marketingCookie
        = cookiePrefElement.querySelector(
            '.--checkbox-marketing'
    ) as HTMLInputElement;

    const resetModal = (): void => {
        cookieBoxElement.classList.remove('resize');
        document.querySelectorAll('.cookiepref__table').forEach(t =>
            t.closest('.cookiepref__table').classList.remove('active')
        );
    };

    const showCookies = (): void => cookieBoxElement.classList.add('--show');
    const hideCookieModal = (): void =>
        [cookieBoxElement, cookiePrefElement].forEach(e => {
            e.classList.remove('--show');
            resetModal();
        });

    const acceptAllCookies = (): void => {
        const cookies = Object.values(Cookie);
        cookies.forEach(c => setGdprConsentCookie(c, true));
        enableGoogleAnalytics();
        enableUxTracking();
    };

    export const bind = async (): Promise<void> => {
        cookieBoxElement.querySelector(
            '.--btn-cookies'
        ).addEventListener('click', () => {
            hideCookieModal();
            acceptAllCookies();
        });

        cookiePrefElement.querySelector(
            '.--btn-confirm'
        ).addEventListener('click', () => {
            if (performanceCookie.checked) {
                setGdprConsentCookie(Cookie.Performance, true);
                enableUxTracking();
            } else {
                deleteCookie(Cookie.Performance);
            }

            if (marketingCookie.checked) {
                setGdprConsentCookie(Cookie.Marketing, true);
                enableGoogleAnalytics();
            } else {
                deleteCookie(Cookie.Marketing);
            }

            setGdprConsentCookie(Cookie.Necessary, true);
            hideCookieModal();
        });

        if (hasPerformanceCookie()) {
            performanceCookie.toggleAttribute('checked', true);
        }
        if (hasMarketingCookie()) {
            marketingCookie.toggleAttribute('checked', true);
        }

        const prefSettingButton = cookieBoxElement.querySelector('.--manage');
        const arrowButton = cookiePrefElement.querySelector('.--c-arrow');
        [prefSettingButton, arrowButton].forEach(e =>
            e.addEventListener('click', () => {
                cookiePrefElement.classList.toggle('--show');
                resetModal();
            }));

        const cookieFooterLink
            = document.querySelector('.footer__right .show-cookies');
        cookieFooterLink.addEventListener('click', () => {
            showCookies();
            cookiePrefElement.classList.add('--show');
        });

        [cookieBoxElement, cookiePrefElement].forEach(e =>
            e.querySelector('.--c-close').addEventListener('click', () =>
                hideCookieModal()));

        // TODO(jay) Make inEurope method to check user's country correctly.
        if (!hasNecessaryCookie()) {
            showCookies();
            return;
        }
    };
}

window.addEventListener('load', () => {
    void CookieConsent.bind().catch(console.error);
});

const setUpUserActivity = async (accessToken?: string) => {
    const activityStub = new UserActivityBrowserStub(new HttpEndpoint(config.serviceUrl.userActivity));
    // If we don't have an accessToken, we can't authenticate the authServiceStub.
    // We still log the same events, but can't assign them to an existing User.
    if (has(accessToken)) {
        await activityStub.authenticate(accessToken);
    }
    window.addEventListener('beforeunload', () => {
        void activityStub.logUserActivity(pageSession(Date.now() - sessionStart));
    });

    void activityStub.logUserActivity(pageView);
    document.querySelectorAll('form').forEach(form => form.addEventListener('submit',
        (e) => {
            e.preventDefault();
            void activityStub.logUserActivity(newsletter);
            form.submit();
        }));

    if (window.location.href.includes('confirmed')) {
        void activityStub.logUserActivity(completeRegistration);
    }

    const isCalendlyEvent = (e: MessageEvent) => {
        return (e.origin === 'https://calendly.com')
            && (e.data.event?.indexOf('calendly.') === 0)
            && !location.href.includes('webinar');
    };
    const isWebinarEvent = (e: MessageEvent) => {
        return (e.origin === 'https://calendly.com')
            && (e.data.event?.indexOf('calendly.') === 0)
            && location.href.includes('webinar');
    };
    // TODO(roman): Use async listener wrapper instead of voids once it's added.
    window.addEventListener('message', (e: MessageEvent) => {
        if (!isCalendlyEvent(e)) {
            return;
        }
        const n = e.data.event;
        
        if (n === 'calendly.date_and_time_selected') {
            void allLogRejected([activityStub.logUserActivity(dateSelected)]);
        }
        if (n === 'calendly.event_scheduled') {
            gtag_report_conversion(location.href);
            void allLogRejected([activityStub.logUserActivity(eventScheduled)]);
            window.lintrk('track', { conversion_id: 11154018 });
        }
    });
    window.addEventListener('message', (e: MessageEvent) => {
        if (!isWebinarEvent(e)) {
            return;
        }
        const n = e.data.event;
        
        if (n === 'calendly.event_scheduled') {
            window.lintrk('track', { conversion_id: 12117812 });
        }
    });
};

const handleRegisteredUser = (serializableLocation: SerializableLocation, accessToken?: string): void => {
    if (!isEmpty(serializableLocation.hash)) {
        return;
    }

    // Redirect to the IDE if a user exists.
    if (has(accessToken)) {
        location.href = '/ide';
    }
};
// Get google click id
function getParam(p: string) {
    var match = RegExp('[?&]' + p + '=([^&]*)').exec(window.location.search);
    return match && decodeURIComponent(match[1].replace(/\+/g, ' '));
  }
  
  function getExpiryRecord(value: string) {
    var expiryPeriod = 90 * 24 * 60 * 60 * 1000; // 90 day expiry in milliseconds
  
    var expiryDate = new Date().getTime() + expiryPeriod;
    return {
      googleID: value,
      expiryDate: expiryDate
    };
  };

const initCalendly = () => {
    const gclidParam = getParam('gclid');
    //var gclidFormFields = ['gclid_field', 'foobar']; // all possible gclid form field ids here
    
  
    const gclsrcParam = getParam('gclsrc');
    const isGclsrcValid = !gclsrcParam || gclsrcParam.indexOf('aw') !== -1;
  
  
    if (gclidParam && isGclsrcValid) {
      var gclidRecord = getExpiryRecord(gclidParam);
      const s = JSON.stringify(gclidRecord);
      safeLocalStorage.setItem('gclid_full', s);     
    }
    var gclid_full = gclidRecord || JSON.parse(safeLocalStorage.getItem('gclid_full'));
    var isGclidValid = gclid_full && new Date().getTime() < gclid_full.expiryDate;
  
    if (isGclidValid) {
        var gclidCalendly = gclid_full.googleID;
    }
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString); 
    const campaign = urlParams.get('utm_campaign') || 'EcommercePage';
    const u = new URL('https://calendly.com/timo-codesphere/demo-embed');
    u.searchParams.append('hide_gdpr_banner', '1');
    u.searchParams.append('background_color', '110e27');
    u.searchParams.append('text_color', 'bbb6d3');
    u.searchParams.append('primary_color', '814bf6');
    Calendly.initInlineWidget({
        url: u.toString(),
        parentElement: document.getElementById('calendly'),
        prefill: {},
        utm: {
            utmCampaign: campaign,
            utmSource: 'FrontPageEmbed',
            utmTerm: gclidCalendly,
        },
    });
};


const initCalendly2 = () => {
    const u = new URL('https://calendly.com/timo-codesphere/webinar-pagespeed-performance/2023-05-24T17:00:00+02:00?&hide_event_type_details=1&event_start_time=2023-05-18T17%3A00%3A00%2002%3A00');
    u.searchParams.append('hide_gdpr_banner', '1');
    u.searchParams.append('background_color', '110e27');
    u.searchParams.append('text_color', 'bbb6d3');
    u.searchParams.append('primary_color', '814bf6');
    Calendly.initInlineWidget({
        url: u.toString(),
        parentElement: document.getElementById('pagespeedcalendly'),
        prefill: {},
        utm: {
            utmCampaign: 'Webinar',
            utmSource: 'WebinarLanding',
        },
    });
};

const initCalendly3 = () => {
    const u = new URL('https://calendly.com/timo-codesphere/webinar-a-b-tests/2023-06-01T17:00:00+02:00?&hide_event_type_details=1&event_start_time=2023-05-18T17%3A00%3A00%2002%3A00');
    u.searchParams.append('hide_gdpr_banner', '1');
    u.searchParams.append('background_color', '110e27');
    u.searchParams.append('text_color', 'bbb6d3');
    u.searchParams.append('primary_color', '814bf6');
    Calendly.initInlineWidget({
        url: u.toString(),
        parentElement: document.getElementById('abtestcalendly'),
        prefill: {},
        utm: {
            utmCampaign: 'Webinar',
            utmSource: 'WebinarLanding',
        },
    });
};

const initCalendly4 = () => {
    const u = new URL('https://calendly.com/timo-codesphere/webinar-staging-environments/2023-06-15T17:00:00+02:00?&hide_event_type_details=1&event_start_time=2023-05-18T17%3A00%3A00%2002%3A00');
    u.searchParams.append('hide_gdpr_banner', '1');
    u.searchParams.append('background_color', '110e27');
    u.searchParams.append('text_color', 'bbb6d3');
    u.searchParams.append('primary_color', '814bf6');
    Calendly.initInlineWidget({
        url: u.toString(),
        parentElement: document.getElementById('stagingcalendly'),
        prefill: {},
        utm: {
            utmCampaign: 'Webinar',
            utmSource: 'WebinarLanding',
        },
    });
};

const initCalendly5 = () => {
    const u = new URL('https://calendly.com/timo-codesphere/webinar-composable-commerce/2023-06-28T17:00:00+02:00?&hide_event_type_details=1&event_start_time=2023-05-18T17%3A00%3A00%2002%3A00');
    u.searchParams.append('hide_gdpr_banner', '1');
    u.searchParams.append('background_color', '110e27');
    u.searchParams.append('text_color', 'bbb6d3');
    u.searchParams.append('primary_color', '814bf6');
    Calendly.initInlineWidget({
        url: u.toString(),
        parentElement: document.getElementById('composablecommercecalendly'),
        prefill: {},
        utm: {
            utmCampaign: 'Webinar',
            utmSource: 'WebinarLanding',
        },
    });
};

void (async () => {
    const sessionsStub = new SessionStub(new HttpEndpoint(config.serviceUrl.authService));
    const accessToken = (await ignoreErrorAsync(
        async () => sessionsStub.genAccessToken({id: undefined}))
    )?.accessToken;

    router.autorunOnUrl(location => handleRegisteredUser(location, accessToken), path('/'));
    initCalendly();
    initCalendly2();
    initCalendly3();
    initCalendly4();
    initCalendly5();
    await logErrorAsync(setUpUserActivity);
})();
