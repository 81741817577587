import 'imagesloaded';
import * as quicklink from 'quicklink';
import {bindClipboardCopy} from './components/bindClipboardCopy';
import {bindCookiePreferences} from './components/bindCookiePreferences';
import {bindFadeInObserver} from './components/bindFadeInObserver';
import {bindFaq} from './components/bindFaq';
import {bindFeaturedPostSliderMobile} from './components/bindFeaturedPostSliderMobile';
import {bindFeaturesControlMenu} from './components/bindFeaturesControlMenu';
import {bindHeaderToggleMobile} from './components/bindHeaderToggleMobile';
import {bindMenuItemClick} from './components/bindMenuItemClick';
import {bindMobileFooterAccordion} from './components/bindMobileFooterAccordion';
import {bindMobileNav} from './components/bindMobileNav';
import {bindNavbarBox} from './components/bindNavbarBox';
import {bindNotFoundAnimation} from './components/bindNotFoundAnimation';
import {bindPricingAnimation} from './components/bindPricingAnimation';
import {bindScrollDownButton} from './components/bindScrollDownButton';
import {bindWindowResize} from './components/bindWindowResize';
import {init} from './components/init';

document.addEventListener('DOMContentLoaded', () => {
    bindCookiePreferences();
    bindNavbarBox();
    bindHeaderToggleMobile();
    bindMenuItemClick();
    bindMobileNav();
    bindFeaturedPostSliderMobile();
    bindWindowResize();
    bindClipboardCopy();
    bindNotFoundAnimation();
    bindFaq(); // Used in FAQ and Pricing.
    if (location.pathname === '/pricing') {
        bindPricingAnimation();
    }
    bindMobileFooterAccordion();
    bindScrollDownButton(); // Used in Features and Company pages.
    bindFeaturesControlMenu();
    bindFadeInObserver();
    init();
    quicklink.listen();
}, false);
