import {posthog} from 'posthog-js';
import {config} from '@codesphere/user-activity-common/lib/config';
import {getUserActivityMetadata} from "@codesphere/user-activity-browser/lib/UserActivityBrowserStub";
import {has} from "@codesphere/utils-common/lib/has";
​
let initialized = false;
export const enableUxTracking = (): void => {
    const params = new URLSearchParams(window.location.search);
    posthog.init(config.posthog.apiKey,
        {api_host: config.posthog.host});
    posthog.register({tag: config.posthog.tag, env: 'prod'});
    identifyUser();
};
​
const identifyUser = (): void => {
    const m = getUserActivityMetadata();
    if (has(m.userId)) {
        posthog.alias(m.userId.toString());
    }
    if (has(m.anonymousId)) {
        posthog.alias(m.anonymousId);
    }
    if (has(m.fbclid)) {
        posthog.alias(m.fbclid);
    }
    if (has(m.gclid)) {
        posthog.alias(m.gclid);
    }
}
​
const isProduction = (): boolean => {
    return (location.host === 'codesphere.com' || location.host === 'welcome.codesphere.com');
};